<template>
    <div class="main_cont">
        <router-view></router-view>
        <div class="backTop" @click="backTop()">
            <div class="el-icon-download icons"></div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "Main",
    data() {
        return {
        };
    },
    watch: {
        $route: {
            handler: function (val, oldVal) {
                let dtop = document.querySelector('.el-main')
                dtop.scrollTop = 0
                let dis = document.querySelector('.backTop')
                dis.setAttribute('class', 'backTop')
            },
            deep: true
        }
    },
    computed: {
    },
    created() {
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('scroll', this.handleScroll, true)
        })
    },
    methods: {
        handleScroll(e) {
            let dtop = e.target.scrollTop
            let disHei = e.target.offsetHeight / 3
            let dis = document.querySelector('.backTop')
            if (dtop > disHei) {
                dis.setAttribute('class', 'backTop active')
            } else {
                dis.setAttribute('class', 'backTop')
            }
        },
        backTop() {
            let dtop = document.querySelector('.el-main')
            let timer = setInterval(function () {
                let speed = Math.floor(-dtop.scrollTop / 10);
                dtop.scrollTop = dtop.scrollTop + speed;
                if (dtop.scrollTop <= 20) {
                    dtop.scrollTop = 0
                    clearInterval(timer);
                }
            }, 30)
        },
    },
    destroyed() { },
};
</script>
<style scoped lang="scss">
.main_cont {
    position: relative;

    .backTop {
        background-color: #DD2D25;
        border-radius: 5px;
        padding: 8px 12px;
        z-index: 400;
        position: fixed;
        right: 2.4%;
        bottom: calc(2.4% + 90px);
        cursor: pointer;
        opacity: 0;
        transform: translateY(50%);

        .icons {
            font-weight: bold;
            color: #fff;
            font-size: 20px;
            transform: rotateZ(180deg);
        }
    }

    .backTop:hover {
        background-color: #ef5e4a;
    }

    .active {
        opacity: 1;
        animation: showButton 0.8s forwards;
    }
}

@keyframes showButton {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
</style>
  