<template>
  <div id="app" :class="isGray ? 'isGray' : ''">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import { companyStatus } from '@/api/base'
export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
      isGray: 0,
    }
  },
  created() {
    this.init()
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    async init() {
      await companyStatus().then(res => {
        this.isGray = Number(res.data?.status)
      }).catch(err => {
        console.log(err);
      })
    }
  },
};
</script>
<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.isGray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);

  filter: grayscale(100%);

  filter: gray;
}
</style>
