//导入axios
import axios from 'axios'
//使用element-ui Message做消息提醒
import { Message } from 'element-ui'

//1.创建新的axios 实例
const service = axios.create({
  //公共接口（线下地址、测试地址、线上地址）
  baseURL: process.env.VUE_APP_BASE_API,
  //超时时间，单位是ms
  timeout: 5000 
})

//2.请求拦截器
service.interceptors.request.use(
  config => {
    const date = new Date()
    const c_time = Math.trunc(date.getTime() / 1000);
    config.headers['request-time'] = c_time;
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//3.响应拦截器
service.interceptors.response.use(
 //使用Promise进行处理和响应
  response => {
    const res = response.data
    if (response.status != 200) {
      //配置服务器返回的状态码及返回的提示信息
      if (response.status == 400) {
        Message({
          message: res.message,
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(new Error(res.message))
      }
      return Promise.reject(new Error(res.message))
    }else {
      return res
    }
  },
  error => {
    console.log('err' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service